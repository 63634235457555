export const generateLogin365FingerPrint = async (PersistentLoginUtil = {}, rootState = {}) => {
  if (rootState.killswitches?.persistentLoginKSEnabled && rootState.envProps.entryPoint?.toUpperCase() === 'MCOM') {
    return PersistentLoginUtil.getDeviceFingerPrintHeaders();
  }
  return {};
};

export default {
  generateLogin365FingerPrint,
};
