import {
  SET_QUICK_VIEW_PRODUCT_DETAILS,
  SET_REGISTRANT_ITEM_API_RESPONSE,
  SET_REGISTRANT_REQUESTED_QTY,
  SET_REGISTRANT_PURCHASED_QTY,
  SET_ITEM_QTY,
  SET_UPC_DETAILS,
  SET_STORE_ERROR_STATE,
  SET_REGISTRY_ITEM_STATUS,
  UPDATE_PREFERENCE_STATUS,
  SET_QUICK_VIEW_SAVE_ERROR_STATE,
  SET_QUICK_VIEW_PRODUCT_DETAILS_META,
  SET_PRODUCT_DETAILS_API_ERROR_RESPONSE,
  UPDATE_GIFTS_PURCHASED,
  SET_GIFT_CARD_AMOUNT,
  SET_GIFT_CARD_MESSAGE,
  SET_IN_VALID_GIFT_CARD_AMOUNT,
  SET_GIFT_CARD_DELIVERY_OPTION,
} from '@types/mutations';
import {
  FETCH_QUICK_VIEW_PRODUCT_DETAILS,
  UPDATE_PREFERENCE,
  ADD_PURCHASE,
} from '@types/actions';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const UPDATE_PREFERENCE_ENDPOINT = '/regsvc/registry/api/v1/preference/updatePreference';
const ADD_PURCHASE_ENDPOINT = '/regsvc/registry/api/v1/purchase/addPurchase';
export default {
  namespaced: true,
  state: {
    quickViewProductDetails: null,
    quickViewProductDetailsMeta: null,
    quickViewSaveErrorState: false,
    registrantItemQuantitiesApiResponse: null,
    productDetailsApiErrorResponse: null,
    registrantRequestedQty: null,
    registrantPurchasedQty: 0,
    itemQty: 1,
    upcDetails: {},
    errorState: false,
    registryItemStatus: {},
    updatePreferenceStatus: true,
    giftCardAmount: null,
    giftCardMessage: '',
    inValidGiftCardAmount: true,
    giftCardDeliveryOption: '',
  },
  mutations: {
    [SET_QUICK_VIEW_PRODUCT_DETAILS](state, data) {
      state.quickViewProductDetails = data;
    },
    [SET_QUICK_VIEW_PRODUCT_DETAILS_META](state, data) {
      state.quickViewProductDetailsMeta = data;
    },
    [SET_REGISTRANT_ITEM_API_RESPONSE](state, data) {
      state.registrantItemQuantitiesApiResponse = data;
    },
    [SET_REGISTRANT_REQUESTED_QTY](state, data) {
      state.registrantRequestedQty = data;
    },
    [SET_REGISTRANT_PURCHASED_QTY](state, data) {
      state.registrantPurchasedQty = data;
    },
    [SET_ITEM_QTY](state, data) {
      state.itemQty = data;
    },
    [SET_UPC_DETAILS](state, data) {
      state.upcDetails = data;
    },
    [SET_REGISTRY_ITEM_STATUS](state, data) {
      state.registryItemStatus = data;
    },
    [UPDATE_PREFERENCE_STATUS](state, data) {
      state.updatePreferenceStatus = data;
    },
    [SET_STORE_ERROR_STATE](state, payload) {
      state.errorState = payload;
    },
    [SET_QUICK_VIEW_SAVE_ERROR_STATE](state, error) {
      state.quickViewSaveErrorState = error;
    },
    [SET_PRODUCT_DETAILS_API_ERROR_RESPONSE](state, error) {
      state.productDetailsApiErrorResponse = error;
    },
    [SET_GIFT_CARD_AMOUNT](state, data) {
      state.giftCardAmount = data;
    },
    [SET_GIFT_CARD_MESSAGE](state, data) {
      state.giftCardMessage = data;
    },
    [SET_IN_VALID_GIFT_CARD_AMOUNT](state, data) {
      state.inValidGiftCardAmount = data;
    },
    [SET_GIFT_CARD_DELIVERY_OPTION](state, data) {
      state.giftCardDeliveryOption = data;
    },
  },
  actions: {
    async [FETCH_QUICK_VIEW_PRODUCT_DETAILS]({ commit, rootState }, params) {
      commit(SET_STORE_ERROR_STATE, false);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_QUICK_VIEW_PRODUCT_DETAILS, {});
        commit(SET_QUICK_VIEW_PRODUCT_DETAILS_META, {});
        commit(SET_STORE_ERROR_STATE, true);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const res = await $http.get(
`/xapi/digital/v1/product/${params.productId}?_shoppingMode=${params.shoppingMode}&_regionCode=${params.regionCode}&currencyCode=${params.currencyCode}&_customerState=${params.customerState}&_deviceType=${params.deviceType}`,
{ cancelToken: httpSource.token },
        );
        clearTimeout(timer);
        const productData = res.data;
        if (productData && Object.keys(productData).length > 0) {
          commit(SET_QUICK_VIEW_PRODUCT_DETAILS, productData.product[0]);
          commit(SET_PRODUCT_DETAILS_API_ERROR_RESPONSE, null);
        } else {
          throw new Error(productData);
        }
        commit(SET_QUICK_VIEW_PRODUCT_DETAILS_META, productData);
      } catch (error) {
        clearTimeout(timer);
        commit(SET_QUICK_VIEW_PRODUCT_DETAILS_META, {});
        commit(SET_QUICK_VIEW_PRODUCT_DETAILS, null);
        commit(SET_PRODUCT_DETAILS_API_ERROR_RESPONSE, error);
        commit(SET_STORE_ERROR_STATE, true);
      }
    },
    async [UPDATE_PREFERENCE]({ commit, rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
          `${UPDATE_PREFERENCE_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(UPDATE_PREFERENCE_STATUS, false);
          commit(SET_REGISTRANT_ITEM_API_RESPONSE, isError?.[0]);
          commit(SET_QUICK_VIEW_SAVE_ERROR_STATE, true);
        } else {
          commit(UPDATE_PREFERENCE_STATUS, true);
          commit(SET_REGISTRANT_ITEM_API_RESPONSE, response);
          commit(SET_QUICK_VIEW_SAVE_ERROR_STATE, false);
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(UPDATE_PREFERENCE_STATUS, false);
        commit(SET_REGISTRANT_ITEM_API_RESPONSE, error?.response?.data?.errors?.[0]);
        commit(SET_QUICK_VIEW_SAVE_ERROR_STATE, true);
      }
    },
    async [ADD_PURCHASE]({ commit, rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.post(
          `${ADD_PURCHASE_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(UPDATE_PREFERENCE_STATUS, false);
          commit(SET_REGISTRANT_ITEM_API_RESPONSE, isError?.[0]);
          commit(SET_QUICK_VIEW_SAVE_ERROR_STATE, true);
        } else {
          commit(UPDATE_PREFERENCE_STATUS, true);
          commit(SET_REGISTRANT_ITEM_API_RESPONSE, response);
          if (response.data.registryInfo?.result?.[0]?.quantity > 0) {
            commit(`headerBanner/${UPDATE_GIFTS_PURCHASED}`, response.data.registryInfo?.result?.[0]?.quantity, { root: true });
          }
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(UPDATE_PREFERENCE_STATUS, false);
        commit(SET_REGISTRANT_ITEM_API_RESPONSE, error?.response?.data?.errors?.[0]);
        commit(SET_QUICK_VIEW_SAVE_ERROR_STATE, true);
      }
    },
  },
  modules: {},
};
