import { GET_REGISTRANT_GIFT_TRACKER_RESULTS, UPDATE_REGISTRANT_GIFT_TRACKER_STATUS } from '@types/actions';
import {
  SET_REGISTRANT_GIFT_TRACKER_RESULTS, SET_REGISTRANT_GIFT_TRACKER_LOADER, SET_STORE_ERROR_STATE, SET_GIFT_TRACKER_RESULTS_ERROR,
  SET_GIFT_TRACKER_UPDATE_ERROR, SET_GIFT_TRACKER_UPDATE_STATUS_ERROR,
} from '@types/mutations';
import { transformGiftTrackerResults, transformGiftTrackerUpdateStatus } from '@utils/registrantGiftTrackerUtil';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRANT_GIFT_TRACKER_ENDPOINT = '/regsvc/registry/api/v1/registry/getRegistryInfo';
const REGISTRANT_GIFT_TRACKER_STATUS_UPDATE_ENDPOINT = '/regsvc/registry/api/v1/purchase/updatePurchase';

export default {
  namespaced: true,
  state: {
    registrantGiftTrackerResults: {},
    registrantGiftLoader: true,
    errorState: false,
    giftTrackerResultsError: false,
    giftTrackerUpdateError: false,
    statusCode: false,
  },
  getters: {},
  mutations: {
    [SET_REGISTRANT_GIFT_TRACKER_RESULTS](state, registrantGiftTrackerResults) {
      state.registrantGiftTrackerResults = registrantGiftTrackerResults;
    },
    [SET_REGISTRANT_GIFT_TRACKER_LOADER](state, registrantGiftLoader) {
      state.registrantGiftLoader = registrantGiftLoader;
    },
    [SET_STORE_ERROR_STATE](state, payload) {
      state.errorState = payload;
    },
    [SET_GIFT_TRACKER_RESULTS_ERROR](state, payload) {
      state.giftTrackerResultsError = payload;
    },
    [SET_GIFT_TRACKER_UPDATE_ERROR](state, payload) {
      state.giftTrackerUpdateError = payload;
    },
    [SET_GIFT_TRACKER_UPDATE_STATUS_ERROR](state, payload) {
      state.statusCode = payload;
    },
  },
  actions: {
    async [GET_REGISTRANT_GIFT_TRACKER_RESULTS]({ commit, rootState }, payload) {
      commit(SET_STORE_ERROR_STATE, false);
      commit(SET_REGISTRANT_GIFT_TRACKER_LOADER, true);
      commit(SET_GIFT_TRACKER_RESULTS_ERROR, false);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, {});
        commit(SET_STORE_ERROR_STATE, true);
        commit(SET_REGISTRANT_GIFT_TRACKER_LOADER, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.post(
            `${REGISTRANT_GIFT_TRACKER_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
              cancelToken: httpSource.token,
            },
        );
        clearTimeout(timer);
        const registryInfo = transformGiftTrackerResults(response.data?.registryInfo);
        commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, registryInfo);
        return response;
      } catch (error) {
        commit(SET_GIFT_TRACKER_UPDATE_STATUS_ERROR, true);
        SoftSignInValidation(error);
        clearTimeout(timer);
        commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, {});
        commit(SET_GIFT_TRACKER_RESULTS_ERROR, true);
        return error;
      } finally {
        commit(SET_REGISTRANT_GIFT_TRACKER_LOADER, false);
      }
    },
    async [UPDATE_REGISTRANT_GIFT_TRACKER_STATUS]({ commit, state, rootState }, { payload, type }) {
      commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, transformGiftTrackerUpdateStatus(state.registrantGiftTrackerResults, payload, type, 'LOADING'));
      commit(SET_GIFT_TRACKER_UPDATE_ERROR, false);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${REGISTRANT_GIFT_TRACKER_STATUS_UPDATE_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, transformGiftTrackerUpdateStatus(state.registrantGiftTrackerResults, payload, type, isError ? 'ERROR' : 'SUCCESS'));
        return response;
      } catch (error) {
        SoftSignInValidation(error);
        commit(SET_REGISTRANT_GIFT_TRACKER_RESULTS, transformGiftTrackerUpdateStatus(state.registrantGiftTrackerResults, payload, type, 'ERROR'));
        commit(SET_GIFT_TRACKER_UPDATE_ERROR, true);
        return error;
      }
    },
  },
};
