import {
  GET_KILLSWITCH_CONFIG,
  GET_USER_REGISTRIES,
  UPDATE_DEFAULT_REGISTRY,
} from '@types/actions';
import {
  SET_GIFT_CARD_KILLSWITCH,
  SET_E_GIFT_CARD_KILLSWITCH,
  SET_ORDER_STATUS_KILLSWITCH,
  SET_BABY_REGISTRY_KILL_SWITCH,
  SET_IS_AEM_ENABLED_FOR_REGISTRY_KILL_SWITCH,
  SET_CONTEXTUAL_WIDGET_KILLSWITCH,
  SET_PROS_COLLECTION_ENABLED_KILLSWITCH,
  SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH,
  SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH,
} from '@types/mutations';
import { httpGetProtectedAPIHeaders, httpUpdateAPIHeaders } from '@utils/APIUtil';
import { setRegistryDetails } from '@utils/LocalStorageUtil';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRY_CONFIG_ENDPOINT = '/regsvc/registry/api/v1/config/get';
const USER_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/registry/association';
const UPDATE_DEFAULT_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/registry/defaultRegistry';

export default {
  namespaced: true,
  state: {
    giftCardEnabled: false,
    eGiftCardEnabled: false,
    orderStatusEnabledKS: false,
    babyRegistryEnabled: false,
    isAEMEnabledForRegistry: false,
    contextualWidgetEnabled: false,
    prosCollectionEnabled: false,
    shoppingModeForAemAsSiteEnabled: false,
    createRegistryManualSurveyEnabled: false,
  },
  getters: {},
  mutations: {
    [SET_GIFT_CARD_KILLSWITCH](state, data) {
      state.giftCardEnabled = data;
    },
    [SET_E_GIFT_CARD_KILLSWITCH](state, data) {
      state.eGiftCardEnabled = data;
    },
    [SET_ORDER_STATUS_KILLSWITCH](state, data) {
      state.orderStatusEnabledKS = data;
    },
    [SET_BABY_REGISTRY_KILL_SWITCH](state, data) {
      state.babyRegistryEnabled = data;
    },
    [SET_IS_AEM_ENABLED_FOR_REGISTRY_KILL_SWITCH](state, data) {
      state.isAEMEnabledForRegistry = data;
    },
    [SET_CONTEXTUAL_WIDGET_KILLSWITCH](state, data) {
      state.contextualWidgetEnabled = data;
    },
    [SET_PROS_COLLECTION_ENABLED_KILLSWITCH](state, data) {
      state.prosCollectionEnabled = data;
    },
    [SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH](state, data) {
      state.shoppingModeForAemAsSiteEnabled = data;
    },
    [SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH](state, data) {
      state.createRegistryManualSurveyEnabled = data;
    },
  },
  actions: {
    async [GET_KILLSWITCH_CONFIG]({ commit, rootState }, params) {
      try {
        let payload = {};
        if (params.regId) {
          payload = {
            macys_registryid: params.regId,
          };
        }
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.get(`${REGISTRY_CONFIG_ENDPOINT}?configType=KillSwitch&orgId=${params.orgId}`,
          {
            headers: httpGetProtectedAPIHeaders(rootState, { ...payload, ...login365FingerPrint }),
          });

        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_E_GIFT_CARD_KILLSWITCH, false);
          commit(SET_GIFT_CARD_KILLSWITCH, false);
          commit(SET_ORDER_STATUS_KILLSWITCH, false);
          commit(SET_BABY_REGISTRY_KILL_SWITCH, false);
        } else {
          response.data.registryInfo.forEach((item) => {
            if (item.registryConfigKey?.toUpperCase() === 'BABYREGISTRY' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_BABY_REGISTRY_KILL_SWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'EGC' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_E_GIFT_CARD_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'GIFTCARD' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_GIFT_CARD_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'ORDERSTATUS' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_ORDER_STATUS_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'ISAEMENABLEDFORREGISTRY' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_IS_AEM_ENABLED_FOR_REGISTRY_KILL_SWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'CONTEXTUALWIDGETENABLED' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_CONTEXTUAL_WIDGET_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'PROSCOLLECTIONENABLED' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_PROS_COLLECTION_ENABLED_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'SHOPPINGMODEFORAEMASSITEENABLED' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH, true);
            } else if (item.registryConfigKey?.toUpperCase() === 'CREATEREGISTRYMANUALSURVEYENABLED' && item.registryConfigValue?.toUpperCase() === 'TRUE') {
              commit(SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH, true);
            }
          });
        }
      } catch (error) {
        commit(SET_E_GIFT_CARD_KILLSWITCH, false);
        commit(SET_GIFT_CARD_KILLSWITCH, false);
        commit(SET_ORDER_STATUS_KILLSWITCH, false);
        commit(SET_BABY_REGISTRY_KILL_SWITCH, false);
        commit(SET_CONTEXTUAL_WIDGET_KILLSWITCH, false);
        commit(SET_PROS_COLLECTION_ENABLED_KILLSWITCH, false);
        commit(SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH, false);
        commit(SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH, false);
      }
    },
    async [GET_USER_REGISTRIES]({ rootState }, payload) {
      const { fromLanding } = payload;
      delete payload.fromLanding;
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.post(
          `${USER_REGISTRY_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (!isError) {
          setRegistryDetails(response?.data?.registryInfo);
        } else if (!fromLanding) {
          SoftSignInValidation(isError);
        }
      } catch (error) {
        if (!fromLanding) {
          SoftSignInValidation(error);
        }
      }
    },
    async [UPDATE_DEFAULT_REGISTRY]({ rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        await $http.put(`${UPDATE_DEFAULT_REGISTRY_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          });
      } catch (error) {
        SoftSignInValidation(error);
      }
    },
  },
};
