import { isJSON } from '@utils/JSONUtil';

export const setRegistryDetails = (data) => {
  window.localStorage.setItem('userRegistries', isJSON(data) ? JSON.stringify(data) : '{}');
};
export const getRegistryDetails = () => JSON.parse(window.localStorage.getItem('userRegistries'));
export default {
  setRegistryDetails,
  getRegistryDetails,
};
