import { GET_REGISTRANT_GIFT_CALCULATOR } from '@types/actions';
import {
  SET_REGISTRANT_GIFT_CALCULATOR,
  SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE,
  SET_REGISTRANT_HOME_TAB_API_ERROR_STATUS_CODE,
} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpGetProtectedAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRANT_GIFT_CALCULATOR_ENDPOINT = '/regsvc/registry/api/v2/registry/getRegistry';

export default {
  namespaced: true,
  state() {
    return {
      registrantGiftCalculator: null,
      registrantHomeTabApiErrorResponse: null,
      statusCode: false,
    };
  },
  getters: {},
  mutations: {
    [SET_REGISTRANT_GIFT_CALCULATOR](state, registrantGiftCalculator) {
      state.registrantGiftCalculator = registrantGiftCalculator;
    },
    [SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE](state, error) {
      state.registrantHomeTabApiErrorResponse = error;
    },
    [SET_REGISTRANT_HOME_TAB_API_ERROR_STATUS_CODE](state, error) {
      state.statusCode = error;
    },
  },
  actions: {
    async [GET_REGISTRANT_GIFT_CALCULATOR]({ commit, rootState }, params) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.get(
            `${REGISTRANT_GIFT_CALCULATOR_ENDPOINT}?orgId=${params.orgId}&registryGUID=${params.registryGUID}`,
            {
              headers: httpGetProtectedAPIHeaders(rootState, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE, isError?.[0]);
        } else {
          commit(SET_REGISTRANT_GIFT_CALCULATOR, response.data?.registryInfo);
        }
      } catch (error) {
        if (error.response?.data?.status === 401) {
          commit(SET_REGISTRANT_HOME_TAB_API_ERROR_STATUS_CODE, true);
        }
        SoftSignInValidation(error);
        commit(SET_REGISTRANT_GIFT_CALCULATOR, []);
        commit(SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE, error?.response?.data?.errors?.[0]);
      }
    },
  },
};
