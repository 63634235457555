import {
  GET_REGISTRY_SETTINGS_DETAILS,
  UPDATE_REGISTRY_SETTINGS_PRIVACY_DETAIL,
  UPDATE_REGISTRANT_DETAILS,
  UPDATE_REGISTRY_EVENT_DETAIL,
  UPDATE_REGISTRANT_ADDRESS,
  REMOVE_SUCCESS_ERROR_NOTIFICATION,
  DELETE_FUTURE_SHIP_ADDRESS,
} from '@types/actions';
import {
  SET_REGISTRY_SETTINGS_DETAILS,
  SET_PRIVATE_PREFERENCE,
  SET_CONTACT_INFORMATION,
  SET_EVENT_DETAILS,
  SET_SUCCESS,
  SET_ERROR,
  SET_CONTACT_INFORMATION_SUCCESS,
  SET_CONTACT_INFORMATION_ERROR,
  SET_ADDRESS,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_ERROR,
  SET_REGISTRY_SETTINGS_LOADER,
  SET_STORE_ERROR_STATE,
  SET_PRIVACY_PREFERENCE_SUCCESS,
  SET_PRIVACY_PREFERENCE_FAILURE,
  SET_REGISTRYINFO_API_ERROR,
  SET_UPDATE_REGISTRANT_API_ERROR,
  SET_UPDATE_REGISTRY_SETTING_API_ERROR,
  UPDATE_REGISTRANT_ADDRESS_API_ERROR,
  UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR,
  SET_REGISTRYINFO_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_ADDRESS_API_STATUS_ERROR,
  SET_REGISTRY_PRIVACY_CHANGE_ACTION,
  SET_GIFT_CARD_OPTIONS,
  SET_GIFT_CARD_OPTIONS_UPDATE_ERROR,
  SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS,
} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { isJSON } from '@utils/JSONUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const GET_REGISTRY_SETTINGS_ENDPOINT = '/regsvc/registry/api/v1/registry/getRegistryInfo';
const GET_VISIBILITY_STATUS_ENDPOINT = '/regsvc/registry/api/v1/registry'; // Done

const UPDATE_REGISTRANT = '/regsvc/registry/api/v1/updateRegistrantList';

const UPDATE_ADDRESS = '/regsvc/registry/api/v1/updateRegistrantAddress';
const DELETE_FUTURE_SHIP_ADDRESS_ENDPOINT = '/regsvc/registry/api/v1/deleteFutureShipAddress';
const DELETE_REGISTRANT_PARTNER_ENDPOINT = '/regsvc/registry/api/v1/deleteRegistrant';

export default {
  namespaced: true,
  state: {
    registryInfo: {},
    success: false,
    error: false,
    updateRegistrantSuccess: false,
    updateRegistrantError: false,
    updateAddressSuccess: false,
    updateAddressError: false,
    updatePrivacyPreferenceSuccess: false,
    updatePrivacyPreferenceFailure: false,
    registrySettingLoader: true,
    errorState: false,
    registrantGUID: '',
    partnerGUID: '',
    getRegistryInfoApiError: null,
    updateRegistrantApiError: null,
    updatePrivacyPreferenceApiError: null,
    updateRegistrantAddressApiError: null,
    updateRegistryEventDetailApiError: null,
    registryInfoApiErrorDescription: null,
    updateRegistrantApiErrorDescription: null,
    updatetRegistrySettingApiErrorDescription: null,
    updateRegistantAddressApiErrorDescription: null,
    updateRegistryEventDetailApiErrorDescription: null,
    statusCode: false,
    registryPrivacyChangeAction: false,
    physicalGiftCardEnabled: false,
    egiftCardEnabled: false,
  },
  mutations: {
    [SET_REGISTRY_SETTINGS_DETAILS](state, payload) {
      state.registryInfo = payload;
      state.registrantGUID = payload.registryInfo?.registrant?.find((obj) => obj.role === 'Registrant')?.registrantGUID;
      const partner = payload.registryInfo?.registrant?.find((obj) => obj.role === 'Partner');
      if (partner) {
        state.partnerGUID = partner.registrantGUID;
      }
      const attributes = payload?.registryInfo?.registryHeaderData?.attributes;
      const attributesObj = isJSON(attributes) ? JSON.parse(attributes) : {};
      state.physicalGiftCardEnabled = attributesObj?.physicalGiftCardEnabled?.toUpperCase() === 'TRUE';
      state.egiftCardEnabled = attributesObj?.eGiftCardEnabled?.toUpperCase() === 'TRUE';
    },
    [SET_PRIVATE_PREFERENCE](state, payload) {
      state.registryInfo.registryInfo.registryHeaderData.privacyPreference = payload;
    },
    [SET_CONTACT_INFORMATION](state, { payload, action }) {
      let registrant = [];
      if (action === 'DELETE') {
        registrant = state.registryInfo?.registryInfo?.registrant?.filter((item) => item.role !== payload.role);
      } else {
        registrant = payload.registrantInfo?.map(({ email: emailAddress, ...rest }) => ({ emailAddress, ...rest }));
      }
      state.registryInfo.registryInfo.registrant = registrant;
    },
    [SET_EVENT_DETAILS](state, payload) {
      state.registryInfo.registryInfo.registryHeaderData = payload;
    },
    [SET_SUCCESS](state, payload) {
      state.success = payload;
    },
    [SET_ERROR](state, payload) {
      state.error = payload;
    },
    [SET_CONTACT_INFORMATION_SUCCESS](state, payload) {
      state.updateRegistrantSuccess = payload;
    },
    [SET_CONTACT_INFORMATION_ERROR](state, payload) {
      state.updateRegistrantError = payload;
    },
    [SET_ADDRESS](state, payload) {
      const address = payload[0];
      state.registryInfo.registryInfo.registryAddress = address;
    },
    [SET_ADDRESS_SUCCESS](state, payload) {
      state.updateAddressSuccess = payload;
    },
    [SET_ADDRESS_ERROR](state, payload) {
      state.updateAddressError = payload;
    },
    [SET_REGISTRY_SETTINGS_LOADER](state, payload) {
      state.registrySettingLoader = payload;
    },
    [SET_STORE_ERROR_STATE](state, payload) {
      state.errorState = payload;
    },
    [SET_PRIVACY_PREFERENCE_SUCCESS](state, payload) {
      state.updatePrivacyPreferenceSuccess = payload;
    },
    [SET_PRIVACY_PREFERENCE_FAILURE](state, payload) {
      state.updatePrivacyPreferenceFailure = payload;
    },
    [SET_REGISTRYINFO_API_ERROR](state, payload) {
      state.getRegistryInfoApiError = payload;
    },
    [SET_UPDATE_REGISTRANT_API_ERROR](state, payload) {
      state.updateRegistrantApiError = payload;
    },
    [SET_UPDATE_REGISTRY_SETTING_API_ERROR](state, payload) {
      state.updatePrivacyPreferenceApiError = payload;
    },
    [UPDATE_REGISTRANT_ADDRESS_API_ERROR](state, payload) {
      state.updateRegistrantAddressApiError = payload;
    },
    [UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR](state, payload) {
      state.updateRegistryEventDetailApiError = payload;
    },
    [SET_REGISTRYINFO_API_ERROR_DESCRIPTION](state, payload) {
      state.registryInfoApiErrorDescription = payload;
    },
    [SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION](state, payload) {
      state.updatetRegistrySettingApiErrorDescription = payload;
    },
    [SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION](state, payload) {
      state.updateRegistrantApiErrorDescription = payload;
    },
    [SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION](state, payload) {
      state.updateRegistantAddressApiErrorDescription = payload;
    },
    [SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION](state, payload) {
      state.updateRegistryEventDetailApiErrorDescription = payload;
    },
    [SET_UPDATE_REGISTRANT_ADDRESS_API_STATUS_ERROR](state, error) {
      state.statusCode = error;
    },
    [SET_REGISTRY_PRIVACY_CHANGE_ACTION](state, payload) {
      state.registryPrivacyChangeAction = payload;
    },
    [SET_GIFT_CARD_OPTIONS](state, data) {
      state.physicalGiftCardEnabled = data.physicalGiftCardEnabled;
      state.egiftCardEnabled = data.egiftCardEnabled;
    },
  },
  actions: {
    async [GET_REGISTRY_SETTINGS_DETAILS]({ commit, rootState }, payload) {
      commit(SET_REGISTRYINFO_API_ERROR, null);
      commit(SET_STORE_ERROR_STATE, false);
      commit(SET_REGISTRY_SETTINGS_LOADER, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_REGISTRY_SETTINGS_DETAILS, {});
        commit(SET_STORE_ERROR_STATE, true);
        commit(SET_REGISTRY_SETTINGS_LOADER, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.post(
          `${GET_REGISTRY_SETTINGS_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            cancelToken: httpSource.token,
          },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (response.data?.registryInfo) {
          clearTimeout(timer);
          commit(SET_REGISTRY_SETTINGS_DETAILS, response.data);
          commit(SET_REGISTRYINFO_API_ERROR_DESCRIPTION, '');
        } else {
          clearTimeout(timer);
          commit(SET_REGISTRY_SETTINGS_DETAILS, {});
          commit(SET_REGISTRYINFO_API_ERROR, isError ? isError?.[0] : true);
          commit(SET_REGISTRYINFO_API_ERROR_DESCRIPTION, isError ? isError?.[0]?.description : '');
        }
      } catch (error) {
        if (error.response?.data?.status === 401) {
          commit(SET_UPDATE_REGISTRANT_ADDRESS_API_STATUS_ERROR, true);
        }
        SoftSignInValidation(error);
        clearTimeout(timer);
        commit(SET_REGISTRY_SETTINGS_DETAILS, {});
        commit(SET_REGISTRYINFO_API_ERROR, error?.response?.data?.errors?.[0]);
        commit(SET_REGISTRYINFO_API_ERROR_DESCRIPTION, '');
      } finally {
        commit(SET_REGISTRY_SETTINGS_LOADER, false);
      }
    },

    async [UPDATE_REGISTRY_SETTINGS_PRIVACY_DETAIL]({ commit, rootState }, payload) {
      commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, null);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${GET_VISIBILITY_STATUS_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(
            SET_PRIVACY_PREFERENCE_FAILURE,
            true,
          );
          commit(
            SET_PRIVACY_PREFERENCE_SUCCESS,
            false,
          );
          commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, isError?.[0]);
          commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, isError?.[0]?.description);
        } else {
          commit(
            SET_PRIVATE_PREFERENCE,
            payload.basicInfoUpdate.privacyPreference,
          );
          commit(
            SET_PRIVACY_PREFERENCE_SUCCESS,
            true,
          );
          commit(
            SET_PRIVACY_PREFERENCE_FAILURE,
            false,
          );
          commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(
          SET_PRIVACY_PREFERENCE_FAILURE,
          true,
        );
        commit(
          SET_PRIVACY_PREFERENCE_SUCCESS,
          false,
        );
        commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, error?.response?.data?.errors?.[0]);
        commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, '');
      }
    },

    async [UPDATE_REGISTRANT_DETAILS]({ commit, rootState }, { payload, section, action = '' }) {
      commit(SET_UPDATE_REGISTRANT_API_ERROR, null);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        let response = null;
        if (action === 'DELETE') {
          response = await $http.delete(
            `${DELETE_REGISTRANT_PARTNER_ENDPOINT}`,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
              data: payload,
            },
          );
        } else {
          response = await $http.put(
            `${UPDATE_REGISTRANT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
          );
        }
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          if (section === 'CONTACT') {
            commit(
              SET_CONTACT_INFORMATION_ERROR,
              true,
            );
            commit(
              SET_CONTACT_INFORMATION_SUCCESS,
              false,
            );
            commit(SET_UPDATE_REGISTRANT_API_ERROR, isError?.[0]);
            commit(SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION, isError?.[0].description);
          }

          if (section === 'PRIVACY') {
            commit(
              SET_PRIVACY_PREFERENCE_SUCCESS,
              false,
            );
            commit(
              SET_PRIVACY_PREFERENCE_FAILURE,
              true,
            );
            commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, isError?.[0]);
            commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, isError?.[0]?.description);
          }
        } else {
          commit(SET_CONTACT_INFORMATION, { payload, action });
          if (section === 'CONTACT') {
            commit(
              SET_CONTACT_INFORMATION_SUCCESS,
              true,
            );
            commit(
              SET_CONTACT_INFORMATION_ERROR,
              false,
            );
            commit(SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION, '');
          }
          if (section === 'PRIVACY') {
            commit(
              SET_PRIVACY_PREFERENCE_SUCCESS,
              true,
            );
            commit(
              SET_PRIVACY_PREFERENCE_FAILURE,
              false,
            );
            commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, '');
          }
        }
      } catch (error) {
        SoftSignInValidation(error);
        if (section === 'CONTACT') {
          commit(
            SET_CONTACT_INFORMATION_ERROR,
            true,
          );
          commit(
            SET_CONTACT_INFORMATION_SUCCESS,
            false,
          );
          commit(SET_UPDATE_REGISTRANT_API_ERROR, error?.response?.data?.errors?.[0]);
          commit(SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION, '');
        }

        if (section === 'PRIVACY') {
          commit(
            SET_PRIVACY_PREFERENCE_SUCCESS,
            false,
          );
          commit(
            SET_PRIVACY_PREFERENCE_FAILURE,
            true,
          );
          commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, error?.response?.data?.errors?.[0]);
          commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION, '');
        }
      }
    },

    async [UPDATE_REGISTRY_EVENT_DETAIL]({ commit, rootState }, payload) {
      commit(SET_UPDATE_REGISTRY_SETTING_API_ERROR, null);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${GET_VISIBILITY_STATUS_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(
            SET_ERROR,
            true,
          );
          commit(
            SET_SUCCESS,
            false,
          );
          commit(UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR, isError?.[0]);
          commit(SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION, isError?.[0].description);
        } else {
          commit(
            SET_EVENT_DETAILS,
            response.data?.registryInfo,
          );
          commit(
            SET_SUCCESS,
            true,
          );
          commit(SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(
          SET_ERROR,
          true,
        );
        commit(
          SET_SUCCESS,
          false,
        );
        commit(UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR, error?.response?.data?.errors?.[0]);
        commit(SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION, '');
      }
    },

    async [UPDATE_REGISTRANT_ADDRESS]({ commit, state, rootState }, payload) {
      commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, null);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${UPDATE_ADDRESS}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(
            SET_ADDRESS_ERROR,
            true,
          );
          commit(
            SET_ADDRESS_SUCCESS,
            false,
          );
          commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, isError?.[0]);
          commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, isError?.[0].description);
        } else {
          commit(
            SET_ADDRESS,
            payload.registrantInfo.map((obj) => obj.registrantAddress.map((address) => {
              address.registrantGUID = obj.role?.toUpperCase() === 'PARTNER' ? state.partnerGUID : state.registrantGUID; return address;
            })),
          );
          commit(
            SET_ADDRESS_SUCCESS,
            true,
          );
          commit(
            SET_ADDRESS_ERROR,
            false,
          );
          commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(
          SET_ADDRESS_ERROR,
          true,
        );
        commit(
          SET_ADDRESS_SUCCESS,
          false,
        );
        commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, error?.response?.data?.errors?.[0]);
        commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, '');
      }
    },
    async [DELETE_FUTURE_SHIP_ADDRESS]({ commit, state, rootState }, payload) {
      const { maxDate } = payload;
      delete payload.maxDate;
      commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, null);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.delete(
            `${DELETE_FUTURE_SHIP_ADDRESS_ENDPOINT}`,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
              data: payload,
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(
            SET_ADDRESS_ERROR,
            true,
          );
          commit(
            SET_ADDRESS_SUCCESS,
            false,
          );
          commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, isError?.[0]);
          commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, isError?.[0].description);
        } else {
          commit(
            SET_ADDRESS_SUCCESS,
            true,
          );
          commit(
            SET_ADDRESS,
            [state.registryInfo.registryInfo.registryAddress?.map((obj) => {
              if (new Date(obj.dateEffective).toDateString() !== maxDate.toDateString()) {
                return obj;
              }
              return null;
            }).filter((value) => value)],
          );
          commit(
            SET_ADDRESS_ERROR,
            false,
          );
          commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(
          SET_ADDRESS_ERROR,
          true,
        );
        commit(
          SET_ADDRESS_SUCCESS,
          false,
        );
        commit(UPDATE_REGISTRANT_ADDRESS_API_ERROR, error?.response?.data?.errors?.[0]);
        commit(SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION, '');
      }
    },
    async [REMOVE_SUCCESS_ERROR_NOTIFICATION]({ commit }, payload) {
      commit(SET_CONTACT_INFORMATION_SUCCESS, payload);
      commit(SET_CONTACT_INFORMATION_ERROR, payload);
      commit(SET_SUCCESS, payload);
      commit(SET_ERROR, payload);
      commit(SET_PRIVACY_PREFERENCE_SUCCESS, payload);
      commit(SET_PRIVACY_PREFERENCE_FAILURE, payload);
      commit(SET_ADDRESS_SUCCESS, payload);
      commit(SET_ADDRESS_ERROR, payload);
      commit(`giftCard/${SET_GIFT_CARD_OPTIONS_UPDATE_ERROR}`, false, { root: true });
      commit(`giftCard/${SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS}`, false, { root: true });
    },
  },
  modules: {},
};
