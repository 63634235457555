import { GET_REGISTRY_HOME_TAB, GET_VISIBILITY_STATUS, UPDATE_HEADER_BANNER_VALUES } from '@types/actions';
import {
  SET_REGISTRY_HOME_TAB, SET_HEADER_BANNER_ERROR, SET_REGISTRANT_HEADER_BANNER_LOADING, SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR,
  SET_HEADER_BANNER_API_ERROR_RESPONSE, SET_HEADER_BANNER_ERROR_DESCRIPTION, UPDATE_GIFTS_PURCHASED, UPDATE_PRIVACY_PREFERENCE_LOADER,
  SET_PRIVACY_PREFERENCE_API_TIMEOUT, SET_GIFT_CARD_OPTIONS,
} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpGetProtectedAPIHeaders, httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const GET_VISIBILITY_STATUS_ENDPOINT = '/regsvc/registry/api/v1/registry';
const REGISTRY_HOME_TAB_ENDPOINT = '/regsvc/registry/api/v2/registry/getRegistry';

export default {
  namespaced: true,
  state: {
    headerBanner: {},
    registrantHeaderBannerLoading: true,
    error: false,
    shareRegistryVisibilityAPiError: null,
    headerBannerApiErrorResponse: null,
    errorDescription: '',
    privacyPreferenceLoader: false,
    privacyPreferenceApiTimeout: false,
  },
  getters: {},
  mutations: {
    [SET_REGISTRY_HOME_TAB](state, headerBanner) {
      state.headerBanner = headerBanner;
    },
    [SET_HEADER_BANNER_ERROR](state, error) {
      state.error = error;
    },
    [SET_REGISTRANT_HEADER_BANNER_LOADING](state, registrantHeaderBannerLoading) {
      state.registrantHeaderBannerLoading = registrantHeaderBannerLoading;
    },
    [SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR](state, errorPayload) {
      state.shareRegistryVisibilityAPiError = errorPayload;
    },
    [SET_HEADER_BANNER_API_ERROR_RESPONSE](state, error) {
      state.headerBannerApiErrorResponse = error;
    },
    [SET_HEADER_BANNER_ERROR_DESCRIPTION](state, errorDescription) {
      state.errorDescription = errorDescription;
    },
    [UPDATE_GIFTS_PURCHASED](state, updateGiftsPurchased) {
      state.headerBanner.giftsPurchased += updateGiftsPurchased;
    },
    [UPDATE_PRIVACY_PREFERENCE_LOADER](state, value) {
      state.headerBanner.privacyPreferenceLoader = value;
    },
    [SET_PRIVACY_PREFERENCE_API_TIMEOUT](state, value) {
      state.privacyPreferenceApiTimeout = value;
    },
    [SET_GIFT_CARD_OPTIONS](state, data) {
      state.headerBanner.physicalGiftCardEnabled = data.physicalGiftCardEnabled;
      state.headerBanner.egiftCardEnabled = data.egiftCardEnabled;
    },
  },
  actions: {
    async [GET_REGISTRY_HOME_TAB]({ commit, rootState }, params) {
      commit(SET_REGISTRANT_HEADER_BANNER_LOADING, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_REGISTRY_HOME_TAB, {});
        commit(SET_HEADER_BANNER_ERROR, true);
        commit(SET_REGISTRANT_HEADER_BANNER_LOADING, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.get(
            `${REGISTRY_HOME_TAB_ENDPOINT}?orgId=${params.orgId}&registryGUID=${params.registryGUID}`,
            {
              headers: httpGetProtectedAPIHeaders(rootState, login365FingerPrint),
              cancelToken: httpSource.token,
            },
        );
        clearTimeout(timer);
        const isError = response?.data?.errors || response?.data?.error;
        if (response.data?.registryInfo) {
          commit(SET_REGISTRY_HOME_TAB, response.data?.registryInfo);
          commit(SET_HEADER_BANNER_ERROR, false);
        } else {
          commit(SET_REGISTRY_HOME_TAB, {});
          commit(SET_HEADER_BANNER_ERROR, true);
          commit(SET_HEADER_BANNER_API_ERROR_RESPONSE, { response });
          commit(SET_HEADER_BANNER_ERROR_DESCRIPTION, isError?.[0]?.description);
        }
      } catch (error) {
        SoftSignInValidation(error);
        clearTimeout(timer);
        commit(SET_REGISTRY_HOME_TAB, {});
        commit(SET_HEADER_BANNER_ERROR_DESCRIPTION, '');
        commit(SET_HEADER_BANNER_ERROR, true);
        commit(SET_HEADER_BANNER_API_ERROR_RESPONSE, error);
      } finally {
        commit(SET_REGISTRANT_HEADER_BANNER_LOADING, false);
      }
    },
    async [UPDATE_HEADER_BANNER_VALUES]({ commit, rootState }, params) {
      const httpSource = $http.CancelToken.source();
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.get(
            `${REGISTRY_HOME_TAB_ENDPOINT}?orgId=${params.orgId}&registryGUID=${params.registryGUID}`,
            {
              headers: httpGetProtectedAPIHeaders(rootState, login365FingerPrint),
              cancelToken: httpSource.token,
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (response.data?.registryInfo) {
          commit(SET_REGISTRY_HOME_TAB, response.data?.registryInfo);
          commit(SET_HEADER_BANNER_ERROR, false);
        } else {
          commit(SET_HEADER_BANNER_API_ERROR_RESPONSE, { response });
          commit(SET_HEADER_BANNER_ERROR_DESCRIPTION, isError?.[0]?.description);
        }
      } catch (error) {
        commit(SET_HEADER_BANNER_ERROR_DESCRIPTION, '');
        commit(SET_HEADER_BANNER_API_ERROR_RESPONSE, error);
      }
    },
    async [GET_VISIBILITY_STATUS]({ commit, rootState, state }, payload) {
      commit(SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR, null);
      commit(UPDATE_PRIVACY_PREFERENCE_LOADER, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(UPDATE_PRIVACY_PREFERENCE_LOADER, false);
        commit(SET_PRIVACY_PREFERENCE_API_TIMEOUT, true);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${GET_VISIBILITY_STATUS_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        clearTimeout(timer);
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR, isError?.[0]);
        } else {
          state.headerBanner.privacyPreference = payload.basicInfoUpdate.privacyPreference;
          commit(UPDATE_PRIVACY_PREFERENCE_LOADER, false);
          commit(SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR, null);
        }
      } catch (error) {
        clearTimeout(timer);
        SoftSignInValidation(error);
        commit(SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR, error?.response?.data?.errors?.[0]);
      }
    },
  },
};
