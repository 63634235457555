import { GET_CUSTOMER_INFO } from '@types/actions';
import { SET_CUSTOMER_INFO, SET_CUSTOMER_INFO_LOADING, SET_CUSTOMER_INFO_API_ERROR_RESPONSE } from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpGetProtectedAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const CUSTOMER_INFO_ENDPOINT = '/regsvc/registry/api/v0/registry/search/user';
export default {
  namespaced: true,
  state: {
    customerInfo: {},
    customerInfoLoading: true,
    customerInfoApiErrorResponse: null,
  },
  getters: {},
  mutations: {
    [SET_CUSTOMER_INFO](state, customerInfo) {
      state.customerInfo = customerInfo;
    },
    [SET_CUSTOMER_INFO_LOADING](state, customerInfoLoading) {
      state.customerInfoLoading = customerInfoLoading;
    },
    [SET_CUSTOMER_INFO_API_ERROR_RESPONSE](state, error) {
      state.customerInfoApiErrorResponse = error;
    },
  },
  actions: {
    async [GET_CUSTOMER_INFO]({ commit, rootState }, { params }) {
      commit(SET_CUSTOMER_INFO_LOADING, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_CUSTOMER_INFO, {});
        commit(SET_CUSTOMER_INFO_LOADING, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.get(`${CUSTOMER_INFO_ENDPOINT}?orgId=${params.orgId}`, {
          headers: httpGetProtectedAPIHeaders(rootState, login365FingerPrint),
          cancelToken: httpSource.token,
        });
        clearTimeout(timer);
        if (response.data?.registryInfo) {
          commit(SET_CUSTOMER_INFO, response.data.registryInfo);
        } else {
          commit(SET_CUSTOMER_INFO, {});
        }
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_CUSTOMER_INFO, {});
          commit(SET_CUSTOMER_INFO_API_ERROR_RESPONSE, isError?.[0]);
        }
      } catch (error) {
        SoftSignInValidation(error);
        clearTimeout(timer);
        commit(SET_CUSTOMER_INFO, {});
        commit(SET_CUSTOMER_INFO_API_ERROR_RESPONSE, error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0]);
      } finally {
        commit(SET_CUSTOMER_INFO_LOADING, false);
      }
    },
  },
};
