import { GET_REGISTRANT_MY_TAB_INFO, REMOVE_ITEMS_BY_ID } from '@types/actions';
import {
  SET_REGISTRANT_MY_GIFT_RESPONSE, SET_ERROR_VISIBLE, SET_REGISTRANT_MY_GIFT_LOADER, SET_STORE_ERROR_STATE, SET_UPDATED_PRODUCTS,
  SET_REMOVE_ITEM_API_ERROR_RESPONSE, SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE, SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION,
  SET_REGISTRANT_MY_GIFT_API_ERROR_STATUS_CODE,
} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRANT_MY_GIFT_ENDPOINT = '/regsvc/registry/api/v1/registry/getRegistryInfo';
const DELETE_ITEM_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/preference/deletePreference';

export default {
  namespaced: true,
  state() {
    return {
      registrantMyGiftResponse: {},
      errorVisibility: false,
      registrantMyGiftLoader: false,
      errorState: false,
      removeItemApiErrorResponse: null,
      registrantMyGiftApiErrorResponse: null,
      registrantMyGiftApiErrorDescription: '',
      statusCode: false,
    };
  },
  mutations: {
    [SET_REGISTRANT_MY_GIFT_RESPONSE](state, registrantMyGiftResponse) {
      state.registrantMyGiftResponse = registrantMyGiftResponse;
    },
    [SET_UPDATED_PRODUCTS](state, updatedProducts) {
      state.registrantMyGiftResponse.registryItem = updatedProducts;
    },
    [SET_ERROR_VISIBLE](state, payload) {
      state.errorVisibility = payload;
    },
    [SET_REGISTRANT_MY_GIFT_LOADER](state, payload) {
      state.registrantMyGiftLoader = payload;
    },
    [SET_STORE_ERROR_STATE](state, payload) {
      state.errorState = payload;
    },
    [SET_REMOVE_ITEM_API_ERROR_RESPONSE](state, payload) {
      state.removeItemApiErrorResponse = payload;
    },
    [SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE](state, error) {
      state.registrantMyGiftApiErrorResponse = error;
    },
    [SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION](state, registrantMyGiftApiErrorDescription) {
      state.registrantMyGiftApiErrorDescription = registrantMyGiftApiErrorDescription;
    },
    [SET_REGISTRANT_MY_GIFT_API_ERROR_STATUS_CODE](state, error) {
      state.statusCode = error;
    },
  },
  getters: {},
  actions: {
    async [GET_REGISTRANT_MY_TAB_INFO]({ commit, rootState }, payload) {
      commit(SET_STORE_ERROR_STATE, false);
      commit(SET_REGISTRANT_MY_GIFT_LOADER, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_REGISTRANT_MY_GIFT_RESPONSE, {});
        commit(SET_STORE_ERROR_STATE, true);
        commit(SET_REGISTRANT_MY_GIFT_LOADER, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.post(
            `${REGISTRANT_MY_GIFT_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
              cancelToken: httpSource.token,
            },
        );
        clearTimeout(timer);
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_REGISTRANT_MY_GIFT_RESPONSE, {});
          commit(SET_STORE_ERROR_STATE, true);
          commit(SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE, isError?.[0]);
          commit(SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION, isError?.[0]?.description);
        } else {
          commit(SET_REGISTRANT_MY_GIFT_RESPONSE, response.data.registryInfo);
        }
      } catch (error) {
        if (error.response?.data?.status === 401) {
          commit(SET_REGISTRANT_MY_GIFT_API_ERROR_STATUS_CODE, true);
        }
        SoftSignInValidation(error);
        commit(SET_REGISTRANT_MY_GIFT_RESPONSE, {});
        commit(SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE, error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0]);
        commit(SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION, '');
        clearTimeout(timer);
      } finally {
        commit(SET_REGISTRANT_MY_GIFT_LOADER, false);
      }
    },
    async [REMOVE_ITEMS_BY_ID]({ commit, rootState }, payload) {
      commit(SET_ERROR_VISIBLE, false);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        await $http.delete(`${DELETE_ITEM_REGISTRY_ENDPOINT}`, {
          headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          data: payload,
        });
      } catch (error) {
        SoftSignInValidation(error);
        commit(SET_REMOVE_ITEM_API_ERROR_RESPONSE, error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0]);
        commit(SET_ERROR_VISIBLE, true);
      }
    },
  },
};
