import { UPDATE_NUMBER_OF_GUESTS } from '@types/actions';
import {
  SET_UPDATE_NUMBER_OF_GUESTS,
  SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE,
  SET_EDIT_GUEST_COUNT_API_ERROR,
  SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION,

} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRANT_GIFT_CALCULATOR_ENDPOINT = '/regsvc/registry/api/v1/registry';

export default {
  namespaced: true,
  state: {
    updateGuestCount: {},
    editGuestCountApiErrorResponse: null,
    editGuestCountApiError: null,
    editGuestCountApiErrorDescription: '',
  },
  getters: {},
  mutations: {
    [SET_UPDATE_NUMBER_OF_GUESTS](state, updateGuestCount) {
      state.updateGuestCount = updateGuestCount;
    },
    [SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE](state, error) {
      state.editGuestCountApiErrorResponse = error;
    },
    [SET_EDIT_GUEST_COUNT_API_ERROR](state, error) {
      state.editGuestCountApiError = error;
    },
    [SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION](state, error) {
      state.editGuestCountApiErrorDescription = error;
    },
  },
  actions: {
    async [UPDATE_NUMBER_OF_GUESTS]({ commit, rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, rootState);
        const response = await $http.put(
            `${REGISTRANT_GIFT_CALCULATOR_ENDPOINT }`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_UPDATE_NUMBER_OF_GUESTS, response.data?.registryInfo);
          commit(SET_EDIT_GUEST_COUNT_API_ERROR, true);
          commit(SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE, isError?.[0]);
          commit(SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION, isError?.[0]?.description);
        } else {
          commit(SET_UPDATE_NUMBER_OF_GUESTS, []);
          commit(SET_EDIT_GUEST_COUNT_API_ERROR, false);
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(SET_EDIT_GUEST_COUNT_API_ERROR, true);
        commit(SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE, error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0]);
        commit(SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION, '');
      }
    },
  },
};
